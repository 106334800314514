import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "../styles/info.module.css"
import Seo from "../components/seo"
import Header from "../components/Header"
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Tween } from 'react-gsap'

gsap.registerPlugin(ScrollTrigger);

function Info() {

    const info = [
        {
            title: "Front end",
            items: [
                'React + Next',
                "JavaScript + Typescript",
                "CSS + SCSS",
                "GraphQL",
            ]
        },
        {
            title: "Back end",
            items: [
                "Node",
                "Apollo Server",
                "Supabase",
                "Prisma",
                "Headless WordPress",
                "Headless Shopify", 
            ]
        },
        {
            title: "Tools",
            items: [
                "Github, Bitbucket",
                "VS Code",
                "Vercel",
                "Figma",
                "Adobe XD",
            ]
        },
        {
            title: "Music",
            items: [
                "Akai MPC Live",
                "Elektron Digitake",
                "Korg OPSIX",
                "Roland SUPER JV-1080",
                "Novation Bass Station",
            ]
        }
    ]

  return (
    <section className="info">
  
      <Seo title="Info" />
      <Header />
      <section className={styles.info}>

        <div className={styles.description}>

          
          <div className={styles.headingBox}>
            <Tween from={{y: 50 }} duration={2} delay={.7} ease="expo" >
                
              <h2 className={styles.infoHeading}>Bio</h2>
                
            </Tween>
          </div>
          
          <Tween 
            from={{autoAlpha: 0, y: 10}} 
            duration={1} 
            ease="none"
            delay={1.2}
          >
            <p>
              I am a Software Developer and Full-Stack Engineer based in Melbourne, Australia. 
            </p>

            <p>
              I'm currently working at <a className={styles.paraLink} href="https://yourcreative.com.au/" target="_blank">Your Creative</a> building engaging and performant sites with Next.js, and web and native applications with React.
            </p>

            <p>
              In my free time I enjoy DJing, producing tunes and hangin with my cat Grumio 😸. 
            </p>
     
          </Tween>

        </div>

        <Tween from={{ 
            autoAlpha: 0, 
            y: 50, }} 
            duration={1}
            delay={1.4}
            ease="power2"
            
        >
          <div 
            className="image-container" 
            style={{
              marginBottom: '2em',
              overflow: 'hidden'
              }}
          >
              <StaticImage 
                src="../images/ryan.jpg" 
                alt="Ryan" 
                placeholder="blurred"
                layout="constrained"
                />
          </div>
        </Tween>
          <div 
            className={styles.tech}
            style={{
              padding: '2em 0',
              borderBottom: 'var(--border)',
              borderTop: 'var(--border)',
            }}
          >
            <div className={styles.headingBox}>
              <Tween from={{
                y: 50,
                scrollTrigger: {
                  trigger: 'tech-heading',
                  start: 'center bottom',
                  toggleActions: 'play none none reverse',
                }
                }} 
                duration={2} 
                ease="expo" 
              >
                  
                <h2 className={styles.infoHeading} id="tech-heading">Technologies</h2>
                  
              </Tween>
            </div>
            <div className={styles.listBox}> 
              <ul>
                <Tween from={{
                  y: -10,
                  autoAlpha: 0,
                  scrollTrigger: {
                    trigger: 'tech-heading',
                    start: 'center bottom',
                    toggleActions: 'play none none reverse',
                  }
                }}
                  duration={1}
                  stagger={1}
                  ease="none"
                  delay={1}
                >
                  <li>React, Next</li>
                  <li>JavaScript, Typescript</li>
                  <li>CSS + SCSS, HTML</li>
                  <li>GraphQL, Apollo</li>
                  <li>Prisma</li>
                  <li>Supabase</li>
                  <li>Headless Wordpress</li>
                  <li>Headless Shopify / Hydrogen</li>
                    <li>Node, Express</li>
                </Tween>
              </ul>
              <ul>
                <Tween from={{
                      y: -10,
                      autoAlpha: 0,
                      scrollTrigger: {
                        trigger: '#tech-heading',
                        start: 'center bottom',
                        toggleActions: 'play none none reverse',
                      }
                    }}
                    duration={1}
                    stagger={1}
                    ease="none"
                    delay={1.1}
                  >
                    <li>Vs Code</li>
                    <li>Vercel</li>
                  <li>Git, GitHub</li>
                  <li>Source Tree</li>
                    <li>Figma</li>
                    <li>Adobe XD</li>
                </Tween>
              </ul>
            </div>
          </div>
        <div className={styles.description} style={{paddingTop: "2em"}}>
          <div className={styles.headingBox}>
            <Tween from={{
              y: 50,
              scrollTrigger: {
                trigger: '#contact-heading',
                start: 'center bottom',
                toggleActions: 'play none none reverse',
              }
              
              }} 
              duration={2} 
              ease="expo" 
            >
                
              <h2 className={styles.infoHeading} id="contact-heading">Contact</h2>
                
            </Tween>
          </div>
          <Tween 
            from={{
              autoAlpha: 0, 
              y: 10,
              scrollTrigger: {
                trigger: '#contact-para',
                toggleActions: 'play none none reverse',
              }
            }} 
            duration={1} 
            ease="none"
            delay={1}
          >
            <p id="contact-para">If you have any questions, or are keen to work together, I'd love to hear from you:
            </p>
          </Tween>
          <div className={styles.contact} id="contact-list">
            <Tween from={{
              autoAlpha: 0, 
              y: 10,
              scrollTrigger: {
                trigger: '#contact-list',
                toggleActions: 'play none none reverse',
              }
            }} 
            duration={1}
            stagger={1} 
            ease="none"
            delay={1}>
              <a href="mailto:ryanhunterdev@gmail.com">Email</a>
              <a href="https://www.linkedin.com/in/ryan-hunter-dev/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              <a href="https://github.com/ryanhunterdev" target="_blank" rel="noopener noreferrer">GitHub</a>
            </Tween>
          </div>
        </div>


        <div className={styles.description} style={{paddingTop: "2em"}}>
        <div className={styles.headingBox}>
          <Tween from={{
              y: 50,
              scrollTrigger: {
                trigger: '#music-heading',
                start: 'center bottom',
                toggleActions: 'play none none reverse',
              }
              
              }} 
              duration={2} 
              ease="expo" 
            >
                
              <h2 className={styles.infoHeading} id="music-heading">Music</h2>
                
          </Tween>
          </div>
          <Tween 
            from={{
              autoAlpha: 0, 
              y: 10,
              scrollTrigger: {
                trigger: '#music-para',
                toggleActions: 'play none none reverse',
              }
            }} 
            duration={1} 
            ease="none"
            delay={1}
          >
            <p id="music-para">
              If the mention of music made your ears prick up you can check my sounds below:
            </p>
          </Tween>
          <div className={styles.contact} id="music-list">
            <Tween from={{
                autoAlpha: 0, 
                y: 10,
                scrollTrigger: {
                  trigger: '#music-list',
                  toggleActions: 'play none none reverse',
                }
              }} 
              duration={1}
              stagger={1} 
              ease="none"
              delay={1}
              >
              <a href="https://midnight-t.bandcamp.com/" target="_blank" rel="noopener noreferrer">Bandcamp</a>
              <a href="https://soundcloud.com/midnighttenderness" target="_blank" rel="noopener noreferrer">Soundcloud</a>
              <a href="https://skylab-radio.com/artist-shows/midnight-tenderness" target="_blank" rel="noopener noreferrer">Skylab Radio</a>
            </Tween>
          </div>
        </div>
        <Link 
          to="/"
          className={styles.home}
        >
          home
        </Link>

      </section>

    </section>
  )
}

export default Info
